<template>
  <div class="home my-5">
<!--    <div class="row mt-3">-->
<!--      <line-chart :chart-data="chartData"  :height="100" class="mt-3"/>-->
<!--    </div>-->
<!--      <table class="table">-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th>渠道</th>-->
<!--          <th>兑换（张）</th>-->
<!--          <th>刮票（张）</th>-->
<!--          <th>中奖（张）</th>-->
<!--          <th>销售额（张）</th>-->
<!--          <th>奖金（元）</th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--          <tr v-for="row in list" :key="row.index">-->
<!--            <td v-for="item in row" :key="item.index">{{ item }}</td>-->
<!--          </tr>-->
<!--        </tbody>-->
<!--      </table>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import LineChart from "@/components/LineChart";
// import User from "@/model/User";
export default {
  name: 'Home',
  components: {
    // LineChart
  },
  data() {
    return {
      list: [
          ['今日',10000,9000,2000,60000,30000],
          ['最近日天',10000,9000,2000,60000,30000],
          ['最近30天',10000,9000,2000,60000,30000],
      ],
      chartData: {},
      colors: [],
      user: {}
    }
  },
  mounted() {
    this.user = localStorage.getItem("user");
      if(this.user !=null) {
        this.redirect();
      } else {
        this.$router.replace('/login');
      }
    // this.fillData()
  },
  created() {
    // this.initColors();
  },
  methods: {

    redirect() {
      this.user = localStorage.getItem("user");
      let parseUser = JSON.parse(this.user);
      console.log(parseUser)
      let roles = parseUser.roles;
      if(roles.indexOf(1) >= 0) {
        this.$router.replace({path: '/data'});
      } else if(roles.indexOf(2) >= 0) {
        this.$router.replace({path:'/console/stat'})
      } else if(roles.indexOf(3) >= 0) {
        this.$router.replace({path:'/finance'})
      } else if(roles.indexOf(4) >= 0) {
        console.log('销售')
      }
    },
    initColors() {
      this.colors = [
        "#3e95cd", '#f87979', '#f5063e', '#9a05f1', '#3CBA9FFF', '#F6D88BFF', '#F5063EFF', '#055AF1FF', '#9A05F1FF'
      ];
    },
    randomColor() {
      return this.colors.pop();
    },
    getData() {
      return {
        titles:['有效率','成交率', '复购率'],
        labels:['2022-01-01','2022-01-02','2022-01-03','2022-01-04','2022-01-05','2022-01-06','2022-01-07'],
        dataSets:[
          [
            {label:'中国移动',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'积分商城',data:[4, 24, 36, 45, 56, 66 ,70]},
            {label:'腾讯公益',data:[12, 32, 13, 21, 25, 24 ,20]},
          ],
          [
            {label:'美团',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'点评',data:[12, 32, 13, 21, 25, 24 ,20]},
            {label:'抖音',data:[4, 24, 36, 45, 56, 66 ,70]},
          ],
          [
            {label:'美团',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'点评',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'抖音',data:[10, 34, 26, 35, 56, 36 ,10]},
          ]
        ]}
    },
    fillData (t = 0) {
      let activeType = t * 1;
      let eData = this.getData();
      let eDataSets = [];
      for (let i in eData.dataSets[activeType]) {
        let _data = eData.dataSets[activeType][i];
        eDataSets[i] = {
          label: _data.label,
          borderColor: this.randomColor(),
          data: _data.data,
          fill: false
        }
      }
      this.chartData = {
        labels: eData.labels,
        datasets: eDataSets
      }
      console.log(this.chartData)
      this.initColors();
      this.$forceUpdate()
    },
  }

}
</script>
